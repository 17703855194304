;(function() {
  'use strict';

  if (!$) {
    throw new Error('input.js requires jQuery - ($ is not defined)')
  }

  // SETTINGS
  // =====================================

  var components = '*[class^="input--"]'
  var $components = $(components)
  var $inputs = $components.find('input, .textarea')

  var attributes = {
    content: 'data-has-content',
    focus: 'data-has-focus'
  }

  // EVENT LISTENERS
  // =====================================

  $inputs.on('focusin', function (e) {
    var $parent = $(this).parents(components)
    $parent.attr(attributes.focus, true)
    $(this).select()
  })

  $inputs.on('focusout', function (e) {
    var content = hasContent(this)
    var $parent = $(this).parents(components)
    $parent.attr(attributes.content, content)
    $parent.attr(attributes.focus, false)
  })

  $components.on('click', function (e) {
    $(this).find('input').focus()
  })

  $components.find('.stepper--up, .stepper--down, .tag > .icon').on('mousedown', function (e) {
    e.preventDefault()
  })

  $components.find('.stepper--up').on('click', function (e) {
    var $input = $(this).siblings('input[type=number]')
    var value = calcStep($input, false)
    $input.val(value)
  })
  $components.find('.stepper--down').on('click', function (e) {
    var $input = $(this).siblings('input[type=number]')
    var value = calcStep($input, true)
    $input.val(value)
  })

  $components.on('click', '.tag > .icon', function (e) {
    var $tag = $(this).parents('.tag')
    $tag.remove()
  })

  $components.filter('.input--tags').find('input[type="text"]').on('keyup', function (e) {
    var $parent = $(this).parents('.input--tags')
    var $tags = $parent.find('.tag')
    var $last =$tags[$tags.length - 1]
    var content = hasContent(this)
    var textContent = $(this).val().length > 0

    // Enter (return)
    if (textContent && e.keyCode === 13) {
      var text = $(this).val()
      var tag = '<div class="tag" data-tag="' + text + '"><div class="text">' + text + '</div><svg class="icon" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z" fill-rule="evenodd"/></svg></div>'
      $(tag).insertBefore(this)
      $(this).val('')
    }

    // Backspace
    if (content && !textContent && e.keyCode === 8) {
      $last.remove()
    }
  })

  $components.filter('.input--dropdown')
    .attr('tabindex', '0')
    .on('focusin', function (e) {
      $(this).attr(attributes.focus, true)
    })
    .on('focusout', function (e) {
      var $component = $(this)
      var content = $component.find('.content').html() ? true : false
      $component.attr(attributes.focus, false)
      $component.attr(attributes.content, content)
    })
    .on('mousedown', function (e) {
      e.preventDefault()
    })
    .on('click', function (e) {
      var focus = $(this).attr(attributes.focus) === 'true' ? true : false
      return focus ? $(this).blur() : $(this).focus()
    })
    .find('.options > .option').on('click', function (e) {
      var $option = $(this)
      var $parent = $option.parents('.input--dropdown')
      var $selected = $parent.find('.content')
      $selected.html($option.html())
    })

  // PRIVATE API
  // =====================================

  /**
   * @name hasContent
   * Checks to see if an input field
   * has content or not.
   * @param  {HTMLelement} input  DOM node of the input field to check
   * @return {Boolean}
   */
  function hasContent (input) {
    if (input.classList.contains('tags__input')) {
      var $parent = $(input).parents('.input--tags')
      var tags = $parent.find('.tag')
      return tags.length > 0
    }
    else if (['text', 'number'].indexOf(input.type) >= 0) {
      return $(input).val().length > 0
    }
    else if (['radio', 'checkbox'].indexOf(input.type) >= 0) {
      return $(input).parents(components).find('input[type="' +  input.type + '"]:checked').length > 0
    }
    else if (input.classList.contains('textarea')) {
      return $(input).text().length > 0
    }
    else {
      return false
    }
  }

  /**
   * Calculate the value of a numeric input
   * after a step
   * @param  {HTMLelement} $input jQuery object of the input field
   * @param  {Boolean} down       Weather the step is down or not
   * @return {Number}             The value after the step is complete
   */
  function calcStep ($input, down) {
    var step = Number($input.prop('step') || 1)
    var value = Number($input.prop('value') || 0)
    value = down ? value - step : value + step
    return value
  }

}());
