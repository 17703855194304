/*
  query-string v2.1.0
  https://github.com/sindresorhus/query-string/tree/2.1.0

  NOTE
  ---------------------------------------------------------------------------
  This code was originally written to be used with NodeJS & RequireJS modules.
  It is slightly altered in a way so that it can natively run in the browser.
  -
  ~ Jesse van der Pluijm (https://github.com/jessevdp)
 */
var queryString = (function() {
  'use strict';

  var exports = {}

  exports.parse = function (str) {
  	if (typeof str !== 'string') {
  		return {};
  	}

  	str = str.trim().replace(/^(\?|#)/, '');

  	if (!str) {
  		return {};
  	}

  	return str.trim().split('&').reduce(function (ret, param) {
  		var parts = param.replace(/\+/g, ' ').split('=');
  		var key = parts[0];
  		var val = parts[1];

  		key = decodeURIComponent(key);
  		// missing `=` should be `null`:
  		// https://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
  		val = val === undefined ? null : decodeURIComponent(val);

  		if (!ret.hasOwnProperty(key)) {
  			ret[key] = val;
  		} else if (Array.isArray(ret[key])) {
  			ret[key].push(val);
  		} else {
  			ret[key] = [ret[key], val];
  		}

  		return ret;
  	}, {});
  };

  exports.stringify = function (obj) {
  	return obj ? Object.keys(obj).sort().map(function (key) {
  		var val = obj[key];

  		if (Array.isArray(val)) {
  			return val.sort().map(function (val2) {
  				return encodeURIComponent(key) + '=' + encodeURIComponent(val2);
  			}).join('&');
  		}

  		return encodeURIComponent(key) + '=' + encodeURIComponent(val);
  	}).join('&') : '';
  };

  return exports
}());
