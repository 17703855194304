;(function() {
  'use strict';

  if (!$) {
    throw new Error('input.js requires jQuery - ($ is not defined)')
  }

  // SETTINGS
  // =====================================

  var components = '[class^="modal--"]'
  var $components = $(components)

  // EVENT LISTENERS
  // =====================================

  $components.on('click', '.icon', function (e) {
    var $modal = $(this).parents(components)
    $modal.hide()
  })

  // Hide components on document load
  $(document).ready(function () {
    $components.hide()
  })

}());
