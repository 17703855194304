;(function() {
  'use strict'

  if (!$) {
    throw new Error('search.js requires jQuery - ($ is not defined)')
  }

  // CONFIGURATION

  var $component = $('#search'),
      $tags = $component.find('.tags'),
      $searchbox = $component.find('.searchbox'),
      $input = $searchbox.find('input')

  var $context = $('section.search-section, section.additional')

  // INTERNAL FUNCTIONS

  /**
   * Search for a term in a given context and mark it.
   * @param  {jQuery} $context jQuery object of the context to search in. – e.g. $('section')
   * @param  {String} term     Search term
   * @return {Void}
   */
  function search ($context, term) {
    $context.show().unmark()

    if (!term) {
      return
    }

    $context.mark(term, {
      separateWordSearch: false,
      done: function() {
        $context.not(":has(mark)").hide()
      }
    })
  }

  /**
   * Get a string of all the selected tags
   * @return {String} All selected tags separated by a space
   */
  function getSelectedTags () {
    var tags = ''
    var $tags = $searchbox.find('.tag')

    $tags.each(function () {
      var prefix = (tags.length < 1) ? '' : ' '
      var tag = $(this).attr('data-tag')
      tags += prefix + tag
    })

    return tags
  }

  /**
   * Run our search
   * @return {Void}
   */
  function execute () {
    var term = $input.val()
    var tags = getSelectedTags()
    var prefix = (tags.length < 1) ? '' : ' '

    var searchTerm = tags + prefix + term
    searchTerm = searchTerm.replace(/\s+$/, '') // remove trailing space(s)

    search($context, searchTerm)
    setURLSearch(searchTerm)

    /* HACK to triger the textblock script */
    window.dispatchEvent(new Event('resize'))
  }

  /**
   * Gets the value of the 'search' URL parameter
   * @return {String} value of the 'search' component of the URL parameters
   */
  function getURLSearch () {
    var params = queryString.parse(window.location.search)
    return params.search
  }

  /**
   * Set the search parameter of the URL to a value
   * @param {String} string The value to which to set it!
   */
  function setURLSearch (string) {
    var params = queryString.parse(window.location.search)
    params.search = string
    var search = '?' + queryString.stringify(params)

    if (history.pushState) {
      window.history.pushState(null, '', search);
    }
  }

  /**
   * [createTag description]
   * @param  {String} string Text that should appear in the tag
   * @return {HTMLString}    An HTML string that represents a tag
   */
  function createAdditionalTag (string) {
    return '<div class="tag -additional" data-tag="' + string +'"><div class="text">' + string + '</div><svg class="icon" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z" fill="#D2CDC7" fill-rule="evenodd"/></svg></div>'
  }

  // EVENT HANDLERS

  $tags.on('click', '.tag', function (e) {
    $(this).insertBefore($input)
    execute()
  })

  $searchbox.on('click', '.tag .icon', function (e) {
    var $tag = $(this).parents('.tag')

    if ($tag.hasClass('-additional')) {
      $tag.remove()
    } else {
      $tags.append($tag)
    }
    execute()
  })

  $input.on('input', function (e) {
    execute()
  })

  $input.on('keydown', function (e) {
    var content = $(this).val().length > 0
    var backspace = e.keyCode === 8

    if (!backspace || content) { return }

    var $tag = $searchbox.find('.tag').last()
    $tags.append($tag)
    execute()
  })

  $(document).ready(function () {
    var search = getURLSearch()
    if (!search) {
      return
    }
    var tag = createAdditionalTag(search)
    $(tag).insertBefore($input)
    execute()
  })

}());
