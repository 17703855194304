;(function() {
  'use strict'
  var FIREFOX = /Firefox/i.test(navigator.userAgent);
  if (FIREFOX) {
    var s = document.querySelectorAll(".killforfirefox");
      for (var i = 0; i < s.length; i++) {
          var elem = s[i];
          elem.style.display="none";
          elem.innerHTML = '';
          elem.parentNode.removeChild(elem);
      }
  }
}());
